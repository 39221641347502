div.dashed {
    border-style: dashed;
    border-radius:5px; 
    border-color: green;
    width:150px; 
    height:150px;
    text-align: center;
    background-color: white;
}
div.dashed img{
    margin-top: 5px;
    width:130px; 
    height:130px;
}
div.dashed div {
    margin-top: 35px;
    color: green;
}

.dashed:hover{
    cursor: pointer;
}

.dashed p{
    font-size: small;
}